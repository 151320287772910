
/*! #######################################################################

  MeanMenu 2.0.7
  --------

  To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
  display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: relative;
  background: #b7985b;
  padding: 4px 0;
  min-height: 42px;
  z-index: 999999;
}

.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px 13px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #fff;
  height: 3px;
  margin-top: 3px;
}

.mean-container .mean-nav {
  float: left;
  width: 100%;
  background: #0c1923;
  margin-top: 44px;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 1em 5%;
  margin: 0;
  text-align: left;
  color: #fff;
  border-top: 1px solid #383838;
  border-top: 1px solid rgba(255,255,255,0.5);
  text-decoration: none;
  text-transform: uppercase;
}

.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 1em 10%;
  border-top: 1px solid #f1f1f1;
  border-top: 1px solid rgba(255,255,255,0.25);
  opacity: 0.75;
  filter: alpha(opacity=75);
  text-shadow: none !important;
  visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: none;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
  background: #252525;
  background: rgba(255,255,255,0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
  -moz-border-bottom-colors: none !important;
  -moz-border-left-colors: none !important;
  -moz-border-right-colors: none !important;
  -moz-border-top-colors: none !important;
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  border-color: -moz-use-text-color -moz-use-text-color rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.4) !important;
  border-image: none !important;
  border-style: none none solid solid !important;
  border-width: medium medium 1px 1px !important;
  font-weight: 700;
  height: 30px;
  margin-top: 1px;
  padding: 12px 12px 9px !important;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 26px;
  z-index: 2;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: #b7985b;
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}


.mean-remove {
  display: none !important;
}
